<template>
	<div class="container">
		<div class="top-nav-bar">
			<TopNavBar :prt-name.sync="prtName" @search="search" :clear-search-name="clearSearchName" />
			<div class="filter-nav" id="filter-nav">
				<div style="width: 33%; display: flex; padding-left: 5px" @click="filterType">
					<div style="width: 70%" :class="{ 'text-blue': showFilter }" class="text-cut">{{ prtCode ? prtCode : '全部产品' }}</div>
					<div class="flex-center">
						<van-icon v-if="showFilter" name="arrow-up" size="12" color="#36C9FF" style="padding-left: 5px" />
						<van-icon v-else name="arrow-down" size="12" color="#cccccc" style="padding-left: 5px" />
					</div>
				</div>
				<div
					@click="sortBrowse"
					style="
            width: 34%;
            border-left: 1px solid #e5e5e5;
            border-right: 1px solid #e5e5e5;
          "
				>
					<span :class="{ 'text-blue': showSortBrowse }">浏览量</span>
					<van-icon v-if="showSortBrowse" name="arrow-up" size="12" color="#36C9FF" style="padding-left: 5px" />
					<van-icon v-else name="arrow-down" size="12" color="#cccccc" style="padding-left: 5px" />
				</div>
				<div @click="newProduct" style="width: 33%">
					<span :class="{ 'text-blue': showNewProduct }">新品</span>
					<van-icon v-if="showNewProduct" name="arrow-up" size="12" color="#36C9FF" style="padding-left: 5px" />
					<van-icon v-else name="arrow-down" size="12" color="#cccccc" style="padding-left: 5px" />
				</div>
			</div>
			<div class="panel" v-if="showFilter">
				<div class="type-btn">
					<div v-for="(it, index) in categoryList" :key="index">
						<div
							@click="it.childs.length == 0 ? getPrtCode(it.typeName) : ''"
							class="btn"
							:style="{
								background: it.childs.length == 0 ? setBgColor(it) : disableBtn
							}"
						>
							{{ it.typeName }}
						</div>
						<div v-for="(ct, i) in it.childs" :key="i + 'c'" @click="getPrtCode(ct.typeName)" class="btn" :style="{ background: setBgColor(ct) }">
							{{ ct.typeName }}
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="sroll">
			<van-list v-model="loading" @refresh="onRefresh" :finished="finished" @load="onLoad" :immediate-check="immediateCheck" offset="300">
				<div class="pro-content-list">
					<RecommendList v-if="proList.length !== 0" :pro-list="proList" @getInfos="getInfo" />
					<div v-if="showEmpty" class="product-item"><span>暂无产品</span></div>
				</div>
			</van-list>
			<WaterMark style="padding-bottom: 80px" />
		</div>
	</div>
</template>
<script>
import TopNavBar from './components/TopNavBar.vue';
import ProTypeList from './components/ProTypeList.vue';
import RecommendList from './components/RecommendList.vue';
import { getProList, getAppProTypeList, getProList2 } from '@/api';
import Scroll from '@/components/scroll/Scroll.vue';

export default {
	name: 'ProAllList',
	components: {
		TopNavBar,
		ProTypeList,
		RecommendList,
		Scroll
	},
	data() {
		return {
			immediateCheck: false,
			prtCode: '',
			prtName: '',
			proList: [{}],
			showList: false,
			immediate: false,
			noDataText: ' ',
			// 分类
			categoryList: [],
			childList: [], // 子分类
			active: 0,
			needNew: true,
			clearSearchName: '',
			showFilter: false,
			showSortBrowse: false,
			showNewProduct: false,
			sortName: '',
			disableBtn: 'linear-gradient(90deg,#666666,#666666)',
			pageSize: 14,
			page: 1,
			finished: false,
			loading: false,
			total: 0,
			scroll: 0,
			showEmpty: false
		};
	},
	watch: {
		async prtName(newValue, oldValue) {
			this.prtName = newValue;
			this.proList = [];
			this.page = 1;
			this.loading = true;
			this.finished = false;
			await this.onLoad();
		},
	},
	async activated() {
		if (this.$store.state.proType) {
			this.prtCode = this.$route.query.prtName;
			this.proList = [];
			this.page = 1;
			this.loading = true;
			this.finished = false;
			await this.onLoad();
		}
	},
	async mounted() {
		this.active = this.$store.state.proActive;
		await this.getAppProTypeList();
		this.proList = [];
	},
	deactivated() {
		this.clearSearchName = '';
		this.prtName = '';
		this.active = 0;
		this.$store.commit('setProType', false);
	},
	methods: {
		noRepeat(arr) { //去重
			const seen = new Set();
			return arr.filter(item => {
				const duplicate = seen.has(item.uuid);
				seen.add(item.uuid);
				return !duplicate;
			});
		},
		search(prtName) {
			this.prtName = prtName;
			// this.proList = [];
			// this.page = 1;
			// this.loading = true;
			// this.finished = false;
			// this.onLoad();
		},
		onRefresh() {
			// 清空列表数据
			this.proList = [];
			this.page = 1;
			// 重新加载数据
			// 将 loading 设置为 true，表示处于加载状态
			this.onLoad();
		},
		getInfo(data) {
			this.$router.push({
				name: 'ProductDetails',
				query: data
			});
		},
		getScrollY(e) {
			this.timeout = setTimeout(() => {
				this.$refs.myScroller.finishPullToRefresh();
			}, 100);
		},
		getProList2() {
			this.showEmpty = false;
			const params = {
				isOn: 0,
				page: this.page,
				pageSize: this.pageSize,
				shopUuid: this.$store.state.shopUuid,
				prtType: this.prtCode || '', // 分类名
				prtName: this.prtName || '',
				sortName: this.sortName ? this.sortName : '',
				asc: this.asc ? this.asc : ''
			};

			getProList2(params).then(res => {
				if (res.state == 100) {
					if (res.items.length > 0) {
						res.items.map(resp => {
							this.proList.push(resp);
						});
					}
					
					 this.proList = this.noRepeat(this.proList)
					
					if (this.proList.length === 0) {
						this.showEmpty = true;
					}
					this.showFilter = false;
					this.total = res.total;
					// 加载状态结束
					this.loading = false;
					// 数据全部加载完成
					if (this.page * this.pageSize > this.total) {
						this.finished = true;
					} else {
						this.page++;
					}
				}
			});
		},
		filterType() {
			this.showFilter = !this.showFilter;
			if (this.showFilter) {
				this.showSortBrowse = false;
				this.showNewProduct = false;
			}
		},
		sortBrowse() {
			this.showSortBrowse = !this.showSortBrowse;
			this.sortName = 1;
			if (this.showSortBrowse) {
				this.asc = 2;
				this.showFilter = false;
				this.showNewProduct = false;
			} else {
				this.asc = 1;
			}
			this.proList = [];
			this.page = 1;
			this.onLoad();
		},
		newProduct() {
			this.showNewProduct = !this.showNewProduct;
			this.sortName = 2;
			if (this.showNewProduct) {
				this.asc = 2;
				this.showFilter = false;
				this.showSortBrowse = false;
			} else {
				this.asc = 1;
			}
			this.proList = [];
			this.page = 1;
			this.onLoad();
		},
		setBgColor(it) {
			let bgColor = it.colorCode.split(',');
			let carouselColor1 = '#ffffff';
			let carouselColor2 = '#ffffff';
			carouselColor1 = bgColor[0];
			carouselColor2 = bgColor[1];
			return 'linear-gradient(90deg,' + carouselColor1 + ',' + carouselColor2 + ')';
		},
		getPrtCode(prtCode, data) {
			// 子组件监听值 并清空输入框
			this.clearSearchName = prtCode;
			this.prtCode = prtCode;
			this.prtName = '';
			this.proList = [];
			this.page = 1;
			this.loading = true;
			this.finished = false;
			this.onLoad();
		},
		getChildCode(prtCode) {
			// 子组件监听值 并清空输入框
			this.clearSearchName = prtCode;
			this.prtCode = prtCode;
			this.prtName = '';
			this.proList = [];
			this.page = 1;
			this.onLoad();
		},
		getAppProTypeList() {
			const params = {
				shopUuid: this.$store.state.shopUuid
			};
			return getAppProTypeList(params).then(res => {
				if (res.state == 100) {
					this.categoryList = [...res.items];
					if (this.$route.query.prtName) {
						this.prtCode = this.$route.query.prtName;
					} else {
						this.prtCode = this.categoryList[this.active]?.typeName;
					}
				}
			});
		},
		async onLoad() {
			await this.getProList2();
		}
	}
};
</script>

<style lang="less">
// ._v-container>._v-content>.loading-layer{
// 	display: none;
// }
.container {
	// overflow: auto;
	padding-top: 54px;
	// overflow-y: scroll;

	.sroll {
		padding-top: 38px;
	}

	//解决底部出现大量空白，以及列表项显示不全的问题
	._v-container > ._v-content > .pull-to-refresh-layer {
		width: 100%;
		height: 120px;
		margin-top: -120px;
		text-align: center;
		font-size: 32px;
		color: #aaa;
		float: left;
		top: 0;
		left: 0;
	}

	.text-cut {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.text-blue {
		color: #3d51ff;
	}

	.filter-nav {
		padding: 0 0 10px 0;
		width: 100%;
		background-color: #ffffff;
		line-height: 20px;
		display: flex;
		justify-content: space-between;
		text-align: center;
		font-size: 15px;
		color: #505050;
		position: relative;
	}

	.type-btn {
		width: 100%;
		background-color: #ffffff;
		padding: 5px;
		display: flex;
		flex-wrap: wrap;
		height: 40%;
		overflow-y: scroll;

		.btn {
			border-radius: 50px;
			line-height: 20px;
			padding: 6px 15px;
			margin: 4px 5px;
			color: #ffffff;
		}
	}

	&::before {
		content: '';
		display: table;
	}

	.top-nav-bar {
		position: fixed;
		top: 0;
		width: 100%;
		height: 60px;
		z-index: 999;

		.panel {
			height: 100%;
			width: 100%;
			background-color: rgba(0, 0, 0, 0.6);
			position: fixed;
			top: 88px;
		}
	}

	.pro-type {
		width: 100vw;
		overflow: hidden;
		position: fixed;
		top: 60px;
		z-index: 999;
	}

	.pro-content-list {
		width: 100vw;
		// height: 100%;
		// overflow-y: scroll;
		padding-bottom: 40px;

		.product-item {
			width: 361px;
			height: 150px;
			background: #ffffff;
			box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.03);
			border-radius: 10px;
			padding: 10px;
			display: flex;
			justify-content: center;
			align-items: center;
			margin: 0 auto;

			& > span {
				font-size: 24px;
				font-family: PingFang SC;
				font-weight: 700;
				color: #969696;
			}
		}
	}

	.content {
		// overflow: auto;
		// height: calc(100vh - 170px);

		.pro-content-list {
			padding-bottom: 30px;
		}
	}
}
</style>
